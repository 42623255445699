<template>
    <div class="main-layout">
        <div class="content">
            <slot></slot>
        </div>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
    export default {

    };
</script>
