<template>
    <nav>
        <div class="menu-wrapper">
            <div class="logo-wrapper">
                <router-link to="/">
                    <img class="logo" :src="`/static/img/${lightMode ? 'logoDark' : 'logo'}.svg`" />
                </router-link>
            </div>
            <div class="toggles">
                <button @click="toggleThemeColor">
                    <img :class="{ 'toggleIcon': true, 'moon': lightMode }" :src="`/static/img/icons/${lightMode ? 'dark' : 'light'}-mode.svg`" />
                </button>
            </div>
            <div class="menu-wrapper-desktop-mobile">
                <ul class="desktop">
                    <li><router-link to="#about">About</router-link></li>
                    <li><a href="https://jobs.mrynk.nl/" target="_blank">Jobs</a></li>
                    <li>
                        <router-link to="#footer">Contact</router-link>
                    </li>
                </ul>
                <svg class="mobile" @click="handleClick" xmlns="http://www.w3.org/2000/svg" width="18.5" height="13.5" viewBox="0 0 18.5 13.5">
                    <g id="hamburger" data-name="Group 84" transform="translate(-350.25 -128.25)">
                        <line id="line-top" data-name="Line 12" x1="17" transform="translate(351 129)" fill="none" :stroke="lightMode ? '#2b2b2b' : '#fff'" stroke-linecap="round" stroke-width="1.5" />
                        <line id="line-middle" data-name="Line 16" x1="17" transform="translate(351 135)" fill="none" :stroke="lightMode ? '#2b2b2b' : '#fff'" stroke-linecap="round" stroke-width="1.5" />
                        <line id="line-bottom" data-name="Line 17" x1="17" transform="translate(351 141)" fill="none" :stroke="lightMode ? '#2b2b2b' : '#fff'" stroke-linecap="round" stroke-width="1.5" />
                    </g>
                </svg>
            </div>
        </div>
        <transition @enter="startAnim" @leave="leaveAnim">
            <div class="mobile menuList" v-if="menuOpen">
                <ul>
                    <li @click="showMenu">
                        <router-link to="/">Home</router-link>
                    </li>
                    <li @click="showMenu"><router-link to="#about">About</router-link></li>
                    <li @click="showMenu"><a href="https://jobs.mrynk.nl/" target="_blank">Jobs</a></li>
                    <li @click="showMenu">
                        <router-link to="#footer">Contact</router-link>
                    </li>
                </ul>
            </div>
        </transition>
    </nav>
</template>

<style lang="scss" scoped>
     //see'./static/scss/components/nav.scss';
</style>

<script>
    import { gsap } from '@/vendor';
    export default {
        data()
        {
            return {
                menuOpen: false
            };
        },

        computed: {
            lightMode: function()
            {
                return this.$store.getters.lightModeOn;
            }
        },

        methods: {
            showMenu()
            {
                this.menuOpen = !this.menuOpen;
                this.noScroll();
            },
            noScroll()
            {
                if( this.menuOpen === true )
                {
                    document.documentElement.classList.add( 'noscroll' );
                }
                else
                {
                    document.documentElement.classList.remove( 'noscroll' );
                }
            },
            handleClick()
            {
                this.showMenu();
                this.noScroll();
            },
            startAnim( el, done )
            {
                const tl = gsap.timeline({ onComplete: done });

                tl.from( '.mobile.menuList', { autoAlpha: 0, duration: 0.25 }, 0 );
                tl.from( '.mobile li', { autoAlpha: 0, y: -10, stagger: 0.1, duration: 0.5 });
                tl.to( '#hamburger #line-top', { rotation: 45, duration: 0.5 }, 0 );
                tl.to( '#hamburger #line-middle', { autoAlpha: 0, duration: 0.5 }, 0 );
                tl.to( '#hamburger #line-bottom', { transformOrigin: '0% 0%', rotation: -45, duration: 0.5 }, 0 );
            },
            leaveAnim( el, done )
            {
                const tl = gsap.timeline({ onComplete: done });

                tl.to( '.mobile li', { autoAlpha: 0, y: 10, stagger: 0.1, duration: 0.5 }, 0 );
                tl.to( '#hamburger #line-top', { rotation: 0, duration: 0.5 }, 0 );
                tl.to( '#hamburger #line-middle', { autoAlpha: 1, duration: 0.5 }, 0 );
                tl.to( '#hamburger #line-bottom', { transformOrigin: '0% 0%', rotation: 0, duration: 0.5 }, 0 );
                tl.to( '.mobile.menuList', { autoAlpha: 0, duration: 0.25 });
            },
            toggleThemeColor()
            {
                this.$store.dispatch( 'toggleLightMode' );
            }
        }
    };
</script>
