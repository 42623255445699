<template>
    <div id="app">
        <navbar/>
        <ynk-layout>
            <transition mode="out-in"
                        @enter="getAnimateIn"
                        @leave="getAnimateOut"
                        :css="false"
                        appear>
                <router-view :key="$route.path" ref="currentView"></router-view>
            </transition>
        </ynk-layout>
        <ynk-footer></ynk-footer>
    </div>
</template>

<style lang="scss">
    @import './static/scss/app.scss';
</style>

<script>
    import { gsap } from '@/vendor';
    import Main from '@/app/layouts/Main';
    import Footer from '@/app/components/Footer';
    import Nav from '@/app/components/Nav';

    export default {
        name: 'App',

        components: {
            'ynk-layout': Main,
            'ynk-footer': Footer,
            navbar: Nav
        },

        mounted()
        {
            window.addEventListener( 'resize', this.onResize );
            this.onResize();
            this.setGlobalLightMode( this.$store.getters.lightModeOn );
        },

        computed: {
            lightMode: function()
            {
                return this.$store.getters.lightModeOn;
            }
        },

        watch: {
            lightMode( newLightMode )
            {
                this.setGlobalLightMode( newLightMode );
            }
        },

        methods: {
            onResize()
            {
                document.documentElement.style.setProperty( '--vh100', window.innerHeight + 'px' ); // fix for 100vh on iphones
            },

            setGlobalLightMode( yes )
            {
                if( yes )
                {
                    document.documentElement.classList.add( 'light-mode' );
                }
                else
                {
                    document.documentElement.classList.remove( 'light-mode' );
                }
            },

            getAnimateIn( el, done )
            {
                const tl = gsap.timeline({ onComplete: done });
                if( typeof this.$refs.currentView.getAnimateIn === 'function' )
                {
                    tl.add( this.$refs.currentView.getAnimateIn(), 0 );
                }
                else
                {
                    done();
                }
            },

            getAnimateOut( el, done )
            {
                const tl = gsap.timeline({ onComplete: done });
                if( typeof this.$refs.currentView.getAnimateOut === 'function' )
                {
                    tl.add( this.$refs.currentView.getAnimateOut(), 0 );
                }
                else
                {
                    done();
                }
            }
        }
    };
</script>
