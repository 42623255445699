import Vue from 'vue';
import Vuex from 'vuex';

// This package interacts with the LocalStorage.
import createPersistedState from 'vuex-persistedstate';

Vue.use( Vuex );
require( 'es6-promise' ).polyfill();

export default new Vuex.Store({
    strict: true,

    modules: {
    },

    state: {
        settings: {
            lightModeOn: false
        }
    },

    plugins: [createPersistedState({
        paths: ['settings']
    })],

    getters: {
        lightModeOn: ( state ) =>
        {
            return state.settings.lightModeOn;
        }
    },

    mutations: {
        toggleLightMode( state )
        {
            state.settings.lightModeOn = !state.settings.lightModeOn;
        }
    },

    actions: {
        toggleLightMode( context )
        {
            context.commit( 'toggleLightMode' );
        }
    }
});
