<template>
    <div id="footer" class="footer">
        <div>
            <h2 class="title">OUR LAIR</h2><p>’s-Gravelandseweg 59</p><p>1217 EH Hilversum</p>
            <p class="telephone-padding"><a href="tel:+31353030080">+31 (0)35 3030 080</a></p>
            <p><a href="mailto:info@mrynk.nl" target="_blank">info@mrynk.nl</a></p>
        </div>
        <div>
            <h2 class="title">NEW BUSINESS</h2><p>Michel Ypma</p><p>Technical Director</p>
            <p class="telephone-padding"><a href="tel:+31353030082">+31 (0)35 3030 082</a></p>
            <p><a href="mailto:projects@mrynk.nl" target="_blank">projects@mrynk.nl</a></p>
        </div>
        <div>
            <div class="socials">
                <a href="https://www.facebook.com/MrYnk.nl" target="_blank"><img src="/static/img/icons/facebook.svg" width="34" height="34" /></a>
                <a href="https://www.instagram.com/mrynknl/" target="_blank"><img src="/static/img/icons/Instagram.svg" width="34" height="34" /></a>
                <a href="https://www.linkedin.com/company/mrynk/" target="_blank"><img src="/static/img/icons/linkedin.svg" width="34" height="34" /></a>
            </div>
            <p class="disclaimer">&copy; Copyright Mr. Ynk 2022</p>
        </div>
    </div>
</template>

<style lang="scss" scoped>
</style>

<script>
    export default {

    };
</script>
