import Vue from 'vue';
import Router from 'vue-router';

Vue.use( Router );

const appRouter = new Router({
    mode: 'history',
    routes: [
        // generated-routes
        {
            path: '/',
            name: 'Landing',
            component: () =>
            {
                return import( /* webpackChunkName: "landing" */ '@/app/pages/Landing' );
            }
        },

        {
            path: '/demo',
            name: 'Demo',
            component: () =>
            {
                return import( /* webpackChunkName: "demo" */ '@/app/pages/Demo' );
            }
        }
    ],
    scrollBehavior( to, from, savedPosition )
    {
        if( savedPosition )
        {
            return savedPosition;
        }
        else
        {
            const position = {};
            if( to.hash )
            {
                position.selector = to.hash;

                if( to.hash === '#about' )
                {
                    position.offset = { y: 150 };
                }
                if( document.querySelector( to.hash ) )
                {
                    return position;
                }
            }
        }
    }
});

appRouter.beforeEach( ( to, from, next ) =>
{
    if( typeof to.beforeEnter === 'function' ) // check if the route has a beforeEnter function
    {
        to.beforeEnter( to, from, next );
        return;
    }

    next();
});

export default appRouter;
